/*

kleuren

blauw: #3696D5, rgb(54, 150, 213)
grijs1 #c1c1c1, rgb(193, 193, 193)
grijs2 #eeeeee, rgb(238, 238, 238)
wit #fff
tekst #444

*/
$blauw: rgb(54, 150, 213);
$grijs1: rgb(193, 193, 193);
$grijs2: rgb(238, 238, 238);
$wit: rgb(255, 255, 255);
$tekst: rgb(68, 68, 68);

$gutter: 2em;

body {
    background: $grijs1;
    background: $wit;
    // background: red;
    font: normal 15px/1.5em 'Open Sans', sans-serif;
    // margin: $gutter;
    color: $tekst;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 400;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    color: #555;
}
.h1 {
    font-size: 1.75em;
}

/**
 * buttons
 */

.btn {
    padding: 0.75em 2em;
    border: 1px solid $blauw;
    background: $blauw;
    color: $wit;
    transition: all 0.75s cubic-bezier(.25,.8,.25,1);
}

.btn:hover {
    background: transparent;
    border: 1px solid $blauw;
    color: $blauw;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

a:hover, a:active, a:focus {
    text-decoration: none;
}

/**
 * Menu
 */

.menu {
    background: $wit;
    padding: 1em 0;
}
.logo {
    text-align: center;
}
.logo img {
    max-height: 6em;
}

.menu .navbar-collapse {
    border: none;
}
.menu .navbar-default {
    background: none;
    border: none;
    margin-bottom: 0;

    // background: $grijs2;
    color: $wit;
    border-radius: 0;
}

// .menu ul.navbar-nav {
    // margin: 0;
    // padding: 0;
    // text-align: right;
    // float: right;
    // border: 1px solid $grijs2;
// }
.menu .navbar-nav li {
    border-bottom: 1px solid $grijs2;
}
.navbar-header {
    border: 0;
}


.menu a:link,
.menu a:visited {
    padding: ($gutter * 1.125) 1em;
}

.menu a:link,
.menu a:visited,
.menu li a:link,
.menu li a:visited {
    color: $tekst;
}

.menu a:hover,
.menu a:active,
.menu li a:hover,
.menu li a:active {
    color: $blauw;
}

button.navbar-toggle {
    border: 0;
}

.navbar-default .navbar-toggle .icon-bar {
    background: $grijs1;
}


/**
 * Show / Slider
 */

.show {
    position: relative;
    overflow: hidden;
    display: block;
    padding: $gutter;
    background: $grijs2;
}
.show-container {
    position: relative;
    overflow: hidden;
    background:gold;
    margin: 0 ($gutter / 2);
}

.slider {
    position: absolute;
    overflow: hidden;
    left: 0;
    height: 8em;
    width: 200%;

}
.slide {
    position: relative;
    overflow: hidden;
    display: block;
    width: calc(12.5% - $gutter);
    float: left;
    margin: 0 $gutter 0 0;
    padding: 0;
    background: red;
}
.slide img {
    display: block;
    margin: 0;
    padding: 0;
}

/**
 * Content
 */

.content {
    padding: $gutter ($gutter / 2);
}
.content__wit {
    background: $wit;
}
.content__grijs {
    background: $grijs2;
}
.content__blauw {
    background: $blauw;
    color: $wit;
    a:link, a:visited {
        color: $wit;
        border-color: $grijs1;
    }
    a:hover, a:active, a:focus {
        color: $wit;
        border-color: $wit;
    }
}
.content__nav {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    a {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
}
.content__categories {
    padding-left: ($gutter / 2);
}

.content__fp {
    width: 100%;
    background: $grijs2 url('../../img/template/radars.svg') no-repeat center center;
    background-size: cover;
    .btn {
        font-weight: bold;
        text-transform: uppercase;
    }
}
.kader {
    position: relative;
    overflow: hidden;
    padding: 0 $gutter $gutter;
    background: rgba(255,255,255,.75);
}


.content.mb1 {
    margin-bottom: $gutter;
}
.content.mb2 {
    margin-bottom: $gutter * 2;
}
.content.mb3 {
    margin-bottom: $gutter * 3;
}
.content.pb1 {
    padding-bottom: $gutter;
}
.content.pb2 {
    padding-bottom: $gutter * 2;
}
.content.pb3 {
    padding-bottom: $gutter * 3;
}


/**
 * Categories
 */
#cat-duurzame-energie-milieu:after {
    position: absolute;
    overflow: hidden;
    // content: '▲';
    // content: '+';
    content: '▼';
    right: 1em;
    // font-weight: bold;
    color: $grijs1;
}
// #cat-afval,
// #cat-bodem,
// #cat-biomassa,
// #cat-energiesystemen,
// #cat-energiebesparing,
// #cat-wind,
// #cat-hydropower,
// #cat-geothermie,
// #cat-lucht,
// #cat-water,
// #cat-zon,
.cat-sub {
    padding-left: $gutter;
    font-style: italic;
    color: #777;
    background: $grijs2;
}
.cat-sub-active {
    background: $grijs2;
}



/**
 * Lijst
 */
.lijst-groep  {
    border-top: 1px solid $grijs2;
}

.lijst-groep--sub {
    border-top: none;
    border-bottom: 1px solid $grijs2;
}

.lijst-groep,
.lijst-groep__item {
    margin: 0;
    padding: 0;
}

.lijst-groep__item {
    list-style: none;
    border-bottom: 1px solid $grijs2;
}

.lijst-groep__item--sub {
    padding-left: 1.5em;
    border-top: 1px solid $grijs2;
    border-bottom: none;
}

.lijst-groep__item a:link,
.lijst-groep__item a:visited, {
    display: block;
    padding: 0.5em 0;
    color: $grijs1;
    text-decoration: none;
}

.lijst-groep__item a:hover,
.lijst-groep__item a:active, {
    text-decoration: none;
    color: $blauw;
}

input[name="weg"] {
    display: none;
}

/**
 * Cards
 */
.card {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 1.5em;
    // border-top: 1px solid #ddd;
    // border-radius: 0.3333em;
    box-shadow: 0 1px 4px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.2);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card:hover {
    text-decoration: none;
    box-shadow: 0 7px 28px rgba(0,0,0,0.12), 0 10px 10px rgba(0,0,0,0.24);
    transition: all 0.7s cubic-bezier(.25,.8,.25,1);
}

.card--content {
    padding: 1em;
    // margin-bottom: 0.25em;
    // border-bottom: 1px solid #ddd;
}

.card--image {
    // background: $grijs1;
}

.card--image img {
    width: 100%;
    min-height: 20em;
    max-height: 20em;
    text-align: center;
    margin: 0 auto;
    object-fit: contain;
}

/**
 * Footer
 */

.footer {
    background: $blauw;
    padding: ($gutter * 2) $gutter;
}

.footer h2 {
    margin-top: 0;
    color: $wit;
}

.footer
.footer p,
.footer address {
    margin-bottom: 0;
    color: $wit;
}

.footer-nav {
    margin: 0;
    padding: 0;
    border-top: 1px solid transparentize($wit, 0.875);
}

.footer-nav li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-nav a:link,
.footer-nav a:visited {
    padding: 0.75em 0;
    display: block;
    color: transparentize($wit, 0.25);
    border-bottom: 1px solid transparentize($wit, 0.875);
}

.footer-nav a:hover,
.footer-nav a:active {
    color: $wit;
    text-decoration: none;
}

/**
 * Bottom
 */

.bottom {
    background: $tekst;
    color: transparentize($wit, 0.75);
    text-align: center;
}

.bottom p {
    margin-bottom: 0;
    padding: ($gutter / 2) $gutter;
}


@media screen and (min-width: 768px) {
    body,
    .form-control,
    .btn {
        font-size: 15px;
    }
    .menu ul.navbar-nav {
        text-align: right;
        float: right;
        border: 0;
    }
    .menu .navbar-nav li {
        border-bottom: 0;
    }
}

@media screen and (min-width: 1024px) {
    body,
    .form-control,
    .btn {
        font-size: 16px;
    }
}

@media screen and (min-width: 1200px) {
    body,
    .form-control,
    .btn {
        font-size: 17px;
    }
}

@media screen and (min-width: 1440px) {
    body,
    .form-control,
    .btn {
        font-size: 19px;
    }
}

@media screen and (min-width: 1920px) {
    body,
    .form-control,
    .btn {
        font-size: 22px;
    }
}


// textarea#editor {
//     height: auto;
//     overflow: auto;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
// }